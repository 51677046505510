import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { 
    currConsultancy: {},
    consultancies: []
}

export const consultanciesDataSlice = createSlice({
    name: 'consultanciesData',
    initialState: {
        value: initialStateValue
    },
    reducers: {
        setCurrConsultancy: (state, action) =>  {
            state.value.currConsultancy = action.payload
        },
        updateCurrConsultancy: (state, action) => {
            const { key, value } = action.payload
            state.value.currConsultancy[key] = value
        },
        setConsultancies: (state, action) => {
            state.value.consultancies = action.payload
        },
        updateConsultancy: (state, action) => {
            const consultancyIndex = state.value.consultancies.findIndex((oldConsultancy) => oldConsultancy._id === action.payload._id)
            if (consultancyIndex !== -1) {
                state.value.consultancies[consultancyIndex] = action.payload
            }
            else {
                state.value.consultancies.push(action.payload)
            }
        },
    }
})

export const { setCurrConsultancy, updateCurrConsultancy, setConsultancies, updateConsultancy } = consultanciesDataSlice.actions

export default consultanciesDataSlice.reducer