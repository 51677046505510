import logo from "./Demacia_Crest.png";
import bg from "./bg.png"
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {Typography, TextField, Button, createTheme} from '@mui/material';

import axios from 'axios'
import { useState } from "react";

import { login } from '../features/userData';
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";

const Login = (props) => {
    const theme = props.theme;
    const dispatch = useDispatch()

    const showToast = props.showToast
    const setLoginBackdropOpen= props.setLoginBackdropOpen
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const onSignInClicked = () => {
      if (email !== '' && password !== '')
      {
        setLoginBackdropOpen(true)
        const loginPromise =  axios.post(process.env.REACT_APP_HEROKU_URL + 'login', {
          email: email.trim().toLowerCase(),
          password: password
        })
       loginPromise.then(response => {
         if (response.status == 200) {
          // Notification.requestPermission().then((result) => {
          //   if (result === 'granted') {
          //     alert('working')
          //   }
          // });
          dispatch(login({...response.data, loggedIn: true}))
         }
       })
      }
      else {
        setLoginBackdropOpen(false)
        showToast('error', 'Missing email or password.')
      }
      
    }
    
return (
    <Grid container sx={{height: "100vh", backgroundImage : `url(${bg})`}}>

    <Grid item display={{ xs: "none", sm: "block" }} xs={false} sm={4} sx={{backgroundColor: theme.palette.primary.main}}>
      <Grid container m={1} display="flex"  flexDirection="column" alignContent='center' justifyContent="center">
        <img src={logo} style={{ width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}></img>
        <Typography  fontWeight='bold' margin='8px' align= 'center' sx={{color:'#243037'}}>Welcome to</Typography>
        <Typography variant='h3' margin='8px' style={{fontFamily: "Corben", fontWeight: 'bold'}} sx={{color:'#243037'}} align= 'center'>ECB Greece<br/>Plaza</Typography>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={8} display="flex" >
      <Paper style={{margin: 'auto', width: '300px'}} elevation={10}>
        <Grid  display='flex' height='100%' flexDirection='column' justifyContent='space-between' component='form' onSubmit={(e) => {
              e.preventDefault()
              onSignInClicked()
            }}>
          <Grid  display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' padding='8px'>
            <Typography variant='h5'  fontWeight='bold' margin='16px' color={theme.palette.secondary.main}>Sign in</Typography>
            <Typography  margin='16px'>Enter your login credentials:</Typography>
              <TextField fullWidth label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} margin='normal'/>
              <TextField fullWidth label="Password" type='password' value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" margin='normal'/>
              <Button sx={{m:'8px'}}>Forgot Password?</Button>
              <Button type='submit' onClick={onSignInClicked} fullWidth  variant="contained" sx={{ mb:'8px'}} >Sign in</Button>
          </Grid>

        </Grid>
        
      </Paper>
    </Grid>
  </Grid>
)
}
export default Login