import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider } from '@mui/material';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useEffect, useState } from 'react';
import { Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from  './features/userData';

function App() {
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userData.value)

  const theme = createTheme({
    palette:{
      primary:{
        main: '#ebc52c'
      },
      secondary: {
        main: '#dce6f0' //#dcc38c
      },
      background:{
        paper: '#415662',
        default: '#243037'
      },
      text: {
        primary: '#dce6f0',
      }
    },
    typography: {
      fontFamily: "Lato"
    }
  })
  
  const [toastOpen, setToastOpen] = useState(false)
  const [toastData, setToastData] = useState({})
  const showToast = (severity, message) => {
    setToastData({severity: severity, message: message, key: new Date().getTime()})
    setToastOpen(true)

  }
  const [loginBackdropOpen, setLoginBackdropOpen] = useState(false)
  useEffect(() => {
    if (window.localStorage.getItem('token') && window.localStorage.getItem('token') !== '')
    {
      setLoginBackdropOpen(true)
      const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'token', { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')}})
      promise.then((response) => {
        if (response.status === 200) {
          dispatch(login({...response.data, loggedIn: true, token: window.localStorage.getItem('token')}))
          setLoginBackdropOpen(false)
        }
      })
      promise.catch(reason => {
        showToast('error', reason.response.data.error)
        dispatch(logout())
      })
    }
    
  }, [])
  useEffect(()=> {
    axios.interceptors.request.use(request => {
      setLoginBackdropOpen(true)
      return request
    })
    axios.interceptors.response.use(response => {
      setLoginBackdropOpen(false)
      return response;
    }, error => {
     switch(error.response.status) {
        case 401:
          showToast('error', error.response.data.error)
          setLoginBackdropOpen(false)
          if (!error.response.data.retry) {
            dispatch(logout())
          }
          break
        default:
          showToast('error', 'An error has occured. Please try again')
          setLoginBackdropOpen(false)
          break
     }
     return error;
    });
  }, [])
 
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {!userData.loggedIn? <Login showToast={showToast} theme={theme} setLoginBackdropOpen={setLoginBackdropOpen}/>: <Dashboard showToast={showToast} theme={theme}/>}
        <Snackbar
          open={toastOpen}
          key={toastData.key}
          autoHideDuration={6000}
          onClose={() => setToastOpen(false)}
          >
            <Alert variant='filled' severity={toastData.severity}>{toastData.message}</Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
          open={loginBackdropOpen}
        >
          <CircularProgress color='primary' />
        </Backdrop>

      </LocalizationProvider>
    </ThemeProvider>

  );
}

export default App;
