import { GREEK_ENTITIES, COUNTRIES } from '../constants';
import {Typography, Paper, ToolTip, Stack, ListItem, Autocomplete, Fab, Slide, Divider, ListItemText, List, TextField, Button, Box, AppBar, Toolbar, IconButton, createTheme, Icon, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Menu, MenuItem, Tooltip, Select, InputLabel, FormControl, Grid} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import HistoryIcon from '@mui/icons-material/History';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrBreak, updateCurrBreak, setBreaks, updateBreak } from '../features/breaksData';

const BREAKS_FIELDS = {
  'Break ID': '_id',
  'EP Name': 'epName',
  'EP ID': 'epId',
  'OP ID': 'opId',
  'Product': 'product',
  'Greek Entity': 'greekEntity',
  'Other LC': 'otherLC',
  'Country': 'country',
  'Type of Break': 'breakType',
  'EXPA Break': 'expaBreak',
  'Date of APD': 'apdDate',
  'RE Period': 'rePeriod',
  'Reason for Break': 'breakReason',
  'Explain the Reason for the Break': 'breakExplanation',
  'APIP Clause': 'apipClause',
  '[OGX] Should reimbursement policies be clarified?': 'ogxClarification',
  'Drive Link': 'driveLink'
}
const BREAKS_IDS = Object.fromEntries(Object.entries(BREAKS_FIELDS).map(a => a.reverse()))

const DROPDOWN_CHOICES = {
  'product': ['', 'iGV', 'iGTa', 'oGV', 'oGTa', 'oGTe'],
  'greekEntity': GREEK_ENTITIES,
  'breakType': ['', 'Approved', 'Realized', 'Finished', 'Completed'],
  'expaBreak': ['', 'Done', 'Not Done', 'Will Not Be Processed'],
  'breakReason': ['', 'Corona virus', 'RE dates don\'t match', 'Visa refusal', 'Health issues', 'Family issues', 'Change in JD or working hours or salary', 'Emergency in HE territory that puts EP\'s life in danger (Except Covid)', 'EP falsified information related to their background', 'OP declares bankruptcy or is sold to third party', 'EP wants to break 28/56 days before the exchange', 'OP wants to break 28/56 days before the exchange', 'Other'],
  'ogxClarification': ['', 'Yes', 'No'], 
  'country': COUNTRIES
}

const AddBreakFAB = (props) => {
  const addBreak = () => {
    const dispatch = props.dispatch

    props.setEditMode(false)
    dispatch(setCurrBreak(Object.fromEntries(Object.entries(BREAKS_IDS).map(a => [a[0], '']))))
    props.setEditDialogOpen(true)
  }
  const theme = props.theme
  return(
    <Fab onClick={addBreak} color="primary" sx={{position: 'fixed', bottom: theme.spacing(4), right: theme.spacing(4)}}>
      <AddIcon />
    </Fab>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const onInputChange = (value, fieldId, dispatch) => {
  dispatch(updateCurrBreak({key: fieldId, value: value}))
}

const DropdownInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
        <TextField select fullWidth label={BREAKS_IDS[fieldId]} value={value}  onChange={(e) => onInputChange(e.target.value, fieldId, dispatch)} >
          {DROPDOWN_CHOICES[fieldId].map((choice) => {
          return <MenuItem key={choice} value={choice}>{choice}</MenuItem>
          })}
          
        </TextField>
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const AutocompleteInput = React.memo((props) => {
  const {fieldId, strValue, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
        <Autocomplete fullWidth
          disablePortal
          value={{name: strValue}}
          options={DROPDOWN_CHOICES[fieldId]}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => onInputChange(value.name, fieldId, dispatch)}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name}
              
            </Box>
          )
        }
          renderInput={(params) => <TextField {...params} fullWidth label={BREAKS_IDS[fieldId]}/>}
        />
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const DateInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
          <DatePicker
            label={BREAKS_IDS[fieldId]}
            value={value? moment(value, 'DD-MM-YYYY'): null}
            inputFormat='DD/MM/YYYY'
            onChange={(e) => onInputChange(e? e.format('DD/MM/YYYY'): '', fieldId, dispatch)}   
            renderInput={(params) => <TextField {...params} fullWidth/>}
        />
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const DateRangeInput = React.memo((props) => {
  const {fieldId, strValue, dispatch} = props
  
  const onRangeChanged = (value, fieldId ,index) => {
  const currentRange = strValue? strValue.split('-'): ['', '']
  currentRange[index] = value
  onInputChange(currentRange.join('-'), fieldId, dispatch)
  }
  return(
    <>
      <ListItem key={fieldId}>
        <Stack direction='row' sx={{width:'100%'}}>
          <DatePicker
              label={BREAKS_IDS[fieldId] + ' (FIRST DAY)'}
              value={strValue? moment(strValue.split('-')[0], 'DD-MM-YYYY'): null}
              inputFormat='DD/MM/YYYY'
              onChange={(e) => onRangeChanged(e? e.format('DD/MM/YYYY'): '', fieldId, 0)}   
              renderInput={(params) => <TextField {...params} fullWidth/>}
          />
          <DatePicker
              label={BREAKS_IDS[fieldId] + ' (LAST DAY)'}
              value={strValue? moment(strValue.split('-')[1], 'DD-MM-YYYY'): null}
              inputFormat='DD/MM/YYYY'
              onChange={(e) => onRangeChanged(e? e.format('DD/MM/YYYY'): '', fieldId, 1)}   
              renderInput={(params) => <TextField {...params} fullWidth/>}
          />
        </Stack>
         
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const TextInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props

  return (
    <>
      <ListItem key={fieldId}>
        <TextField fullWidth type={fieldId.includes('Id')? 'number': 'text'} label={BREAKS_IDS[fieldId]} variant="outlined" value={value} onChange={(e) => onInputChange(e.target.value, fieldId, dispatch)}/>
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const EditDialog = (props) => {
  const dispatch = props.dispatch
  
  const currBreak = useSelector(((state) => state.breaksData.value.currBreak))

  const showToast = props.showToast

  const updateLocalBreaksData = props.updateLocalBreaksData
  const editDialogOpen = props.editDialogOpen
  const handleEditDialogClose = props.handleEditDialogClose
  const oldDialogData = props.oldDialogData

  const saveBreakChanges = () => {
    const breakId = currBreak._id // for put request only (for post receive the _id from server)
    const {_id, ...breakWithoutId} = currBreak;

    if (props.editMode) {
      var editedFields = []
      var newValues = []
      var oldValues = []
      Object.entries(currBreak).map(([key, value]) => {
        if (key!== '_id' && key!== 'editHistory' && value !== oldDialogData[key]) {
          editedFields.push(key)
          newValues.push(value)
          oldValues.push(oldDialogData[key])
        }
      })
      const editHistory = {
        fullName: JSON.parse(window.localStorage.getItem('userData')).fullName,
        editorId: JSON.parse(window.localStorage.getItem('userData'))._id,
        action: 'edit',
        editDate : moment().utc().toISOString(),
        editedFields: editedFields,
        newValues: newValues,
        oldValues: oldValues
      }
      const updatedBreak = {data: currBreak, editHistory: editHistory}
      const promise = axios.put(process.env.REACT_APP_HEROKU_URL + 'breaks/' + breakId, updatedBreak, { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')}})
      promise.then(((response) => {
        if (response.status === 201) {
          showToast('success', response.data.data)
          updateLocalBreaksData({_id: breakId, epName: currBreak.epName, epId: currBreak.epId, opId: currBreak.opId, product: currBreak.product, greekEntity: currBreak.greekEntity})
          handleEditDialogClose()
        }
        else {
          showToast('error', response.data.error)
        }
      }))
    }
    else {
      const editHistory = {fullName: JSON.parse(window.localStorage.getItem('userData')).fullName,
      editorId: JSON.parse(window.localStorage.getItem('userData'))._id,
      action: 'create',
      editDate : moment().utc().toISOString()
    }
      const createdBreak = {data: breakWithoutId, editHistory: editHistory}
      const promise = axios.post(process.env.REACT_APP_HEROKU_URL + 'breaks/', createdBreak, { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')}})
      promise.then(((response) => {
        if (response.status == 201) {
          showToast('success', response.data.data)
          updateLocalBreaksData({_id: response.data._id, epName: breakWithoutId.epName, epId: breakWithoutId.epId, opId: breakWithoutId.opId, product: breakWithoutId.product, greekEntity: breakWithoutId.greekEntity})
          handleEditDialogClose()
        }
        else {
          showToast('error', response.data.error)
        }
      }))
    }
    
  }
  return (
    <Dialog
      fullScreen
      open={editDialogOpen}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleEditDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {(props.editMode)? 'Edit Break for ' + currBreak.epName: 'Add New Break'}
          </Typography>
          <Button color="inherit" onClick={saveBreakChanges}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <List sx={{mt: '80px'}}>
      {Object.entries(BREAKS_IDS).map(([fieldId, value]) => {
        if (!['_id'].includes(fieldId)){
          if (['product', 'greekEntity', 'breakType', 'expaBreak', 'breakReason', 'ogxClarification'].includes(fieldId))
          {
            return(
              <DropdownInput fieldId={fieldId} value={currBreak[fieldId]} dispatch={dispatch}/>
            )
          }
          else if (['country'].includes(fieldId)) {
            return(
              <AutocompleteInput fieldId={fieldId} value={currBreak[fieldId]} dispatch={dispatch} strValue={currBreak[fieldId]}/>
            )
          }
          else if (['apdDate'].includes(fieldId)) {
            return(
              <DateInput fieldId={fieldId} value={currBreak[fieldId]} dispatch={dispatch} strValue={currBreak[fieldId]}/>
            )
          }
          else if (['rePeriod'].includes(fieldId)) {
            return (
              <DateRangeInput fieldId={fieldId} value={currBreak[fieldId]} dispatch={dispatch} strValue={currBreak[fieldId]}/>
            )
          }
          else {
            return(
              <TextInput fieldId={fieldId} value={currBreak[fieldId]} dispatch={dispatch}/>
            )
          }
          
        }
        }
      )}
      </List>
    </Dialog>
  )
}
const HistoryDialog = (props) => {
  const theme = props.theme
  const showToast = props.showToast

  const historyDialogOpen = props.historyDialogOpen
  const setHistoryDialogOpen = props.setHistoryDialogOpen

  const historyData = props.historyData

  const handleProfileClick = (profileId) => {
    showToast('info', profileId)

  }
  return (
    <Dialog fullWidth height="50vh"
    
    open={historyDialogOpen}
  >
    <DialogTitle sx={{color: theme.palette.primary.main}}>
      Edits History
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{color: theme.palette.secondary.main}}>
        {historyData.map((change) => {
          if (change.action == 'edit')
          {
            return(
              <Paper key={change._id} elevation={3} sx={{mb: '40px', padding: '20px'}}>
              <Stack>
                <Typography fontWeight='bold'>{change.fullName}</Typography>
                <Button onClick={() => handleProfileClick(change.editorId)}>Visit Profile</Button>
                <Typography sx={{mb: '20px'}}>{moment.utc(change.editDate).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</Typography>
              </Stack>
              <Divider/>
              <Stack direction="column" spacing={1}>
                {change.editedFields.map((field, i) =>(
                  <Paper key={field}>
                    {`${BREAKS_IDS[field]}: ${change.oldValues[i]? `"${change.oldValues[i]}"`: 'EMPTY' }  =>  "${change.newValues[i]}"`}
                  </Paper>
                ))}
                
              </Stack>
              <Divider/>
              </Paper>
            )
            }
            else if (change.action == 'create') {
              return (
                <Paper elevation={3} sx={{mb: '40px', padding: '20px'}}>
              <Stack>
                <Typography fontWeight='bold'>{change.fullName}</Typography>
                <Button onClick={() => handleProfileClick(change.editorId)}>Visit Profile</Button>
                <Typography sx={{mb: '20px'}}>This break was created on {moment.utc(change.editDate).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</Typography>
              </Stack>
              <Divider/>
              </Paper>
              )
            }
          
          
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {setHistoryDialogOpen(false)}}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}
const DetailsDialog = (props) => {
  const currBreak = useSelector(((state) => state.breaksData.value.currBreak))

  const {theme, dialogOpen, setDialogOpen} = props
  var data = {
    'Break ID': currBreak._id,
    'EP ID': currBreak.epId,
    'OP ID': currBreak.opId,
    'Product': currBreak.product,
    'Greek Entity': currBreak.greekEntity,
    'Other LC': currBreak.otherLC,
    'Country': currBreak.country,
    'Type of Break': currBreak.breakType,
    'EXPA Break': currBreak.expaBreak,
    'Date of APD': currBreak.apdDate,
    'RE Period': currBreak.rePeriod,
    'Reason for Break': currBreak.breakReason,
    'Explain the Reason for the Break': currBreak.breakExplanation,
    'APIP Clause': currBreak.apipClause,
    '[oGX] Should reimbursement policies be clarified?': currBreak.ogxClarification,
    'Drive Link': currBreak.driveLink
  }
  return (
    <Dialog fullWidth height="50vh"
    
    open={dialogOpen}
  >
    <DialogTitle sx={{color: theme.palette.primary.main}}>
      {currBreak.epName}
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{color: theme.palette.secondary.main}}>
        {Object.entries(data).map(([key, value]) => {
          if (value){
            return(
              <>
              <Typography textAlign='center' fontWeight='bold'>{key}:</Typography>
              <Typography textAlign='center'>{value}</Typography>
              <br/>
            <Divider/>
            <br/>
            </>
            )
          }
          
        }
      )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {setDialogOpen(false)}}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}
const Breaks = (props) => {
  const dispatch = useDispatch()
  const breaksData = useSelector((state) => state.breaksData.value.breaks)

  const showToast = props.showToast

  const [selectedRows, setSelectedRows] = useState([])
  const [editMode, setEditMode] = useState(true)
  const updateLocalBreaksData = (newEntry) => {
    dispatch(updateBreak(newEntry))
  }
  useEffect(() => {
    const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'breaks/all',{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setBreaks(response.data))
      }
      else {
        showToast('error', 'An error has occured.')
      }
    })
  }, [])
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const handleEditDialogClose = () => {
    setEditDialogOpen(false)
  }

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false)
  const [historyData, setHistoryData] = useState([])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [oldDialogData, setOldDialogData] = useState({})

 
  const columns = [
    {field: "epName", headerName: "EP Name", width: 150},
    {field: "epId", headerName: "EP ID", width: 150},
    {field: "opId", headerName: "Opportunity ID", width: 150},
    {field: "product", headerName: "Product", width: 150},
    {field: "greekEntity", headerName: "Greek Entity", width: 150},
    {field: "actions", headerName: "Actions", width: 150, renderCell: (values) => {
      const onViewClick = (e) =>{
        e.stopPropagation()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'breaks/' + values.row._id,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setCurrBreak(response.data))
        setDialogOpen(true)
      }
      else {
        showToast('error', response.data.error)
      }
      })
      .catch((error) => {
        showToast('error', "Couldn\'t get this break details.")
      })
    }
      const onEditClick = (e) =>{
        e.stopPropagation()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'breaks/' + values.row._id,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setCurrBreak(response.data))
        setOldDialogData(response.data)
        setEditMode(true)
        setEditDialogOpen(true)
      }
      else {
        showToast('error', response.data.error)
      }
      })
      }
      const onHistoryClick = (e) => {
        e.stopPropagation()
        axios.interceptors.response.use()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'breaks/' + values.row._id + '/history' ,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} })
        promise.then((response) => {
          if (response.status == 200) {
            setHistoryData(response.data.editHistory)
            setHistoryDialogOpen(true)
          }
          else {
            showToast('error', response.data.error)
          }
          })
      }
      return (
        <Box>
          <Tooltip title="View Break">
          <IconButton onClick={onViewClick} color="primary" component="span">
            <VisibilityIcon />
          </IconButton>
          </Tooltip>

          <Tooltip title="Edit Break">
          <IconButton onClick={onEditClick} color="primary" component="span">
            <EditIcon />
          </IconButton>
          </Tooltip>

          <Tooltip title="View Edits History">
            <IconButton onClick={onHistoryClick} color="primary" component="span">
              <HistoryIcon/>
            </IconButton>
          </Tooltip>
    
    </Box>
    )
    }
  }
  ]
  const theme = props.theme;
  return (
      <Box component="main" sx={{ flexGrow: 1, p: 3}} >
           <HistoryDialog 
           showToast={showToast}
           dispatch={dispatch}
           historyData={historyData}
           historyDialogOpen={historyDialogOpen}
           setHistoryDialogOpen={setHistoryDialogOpen}
           theme={theme}/>
           <DetailsDialog theme={theme} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
           <EditDialog 
              showToast={showToast}
              dispatch={dispatch}
              editDialogOpen={editDialogOpen}
              oldDialogData={oldDialogData}
              handleEditDialogClose={handleEditDialogClose}
              updateLocalBreaksData={updateLocalBreaksData}
              editMode={editMode}
           />
            <Typography variant='h3' align='center' paragraph sx={{fontSize: '5vw', fontFamily: 'Corben', color: theme.palette.primary.main}}>
              Breaks
            </Typography>
            <Typography paragraph align='center' fontWeight='bold' sx={{color: theme.palette.secondary.main}} >
              You can view and edit {breaksData.length} breaks.
            </Typography>
            <Button startIcon={<ContentCopyIcon/>} variant='contained' sx={{m:'20px'}} onClick={() => {
              if (selectedRows.length > 0) {
                var clipboardData = [['Break ID', 'EP Name', 'EP ID', 'Opportunity ID', 'Product', 'Greek Entity'].join('\t')]
                clipboardData = clipboardData.concat(breaksData.filter((row)=> (selectedRows.includes(row._id))).map((row) => (Object.values(row).join('\t'))))
                navigator.clipboard.writeText(clipboardData.join('\n'))
                showToast('success', 'Selected breaks were copied to Clipboard. You can now paste them in a Google Sheet.')
              }
              else {
                showToast('error', 'Selection is empty. Use the checkbox to select some breaks first.')
              }
              
            }
            }>Copy Selected Breaks</Button>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid 
            getRowId={(row) => row._id}
            rows={breaksData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(selectedRows) => {
              setSelectedRows(selectedRows)
            }}
            stickyHeader
          />
            </div>
            {/* <Typography variant='h5' fontWeight='bold' sx={{mt: '40px', mb: '20px'}}color={theme.palette.primary.main}>Stats:</Typography>
            <Paper elevation={4} sx={{width: '100%', height: '200px'}}>

            </Paper> */}
            <AddBreakFAB theme={theme} dispatch={dispatch} setEditMode={setEditMode} setEditDialogOpen={setEditDialogOpen}/>
          </Box>
  )
}
export default Breaks