import {
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import Breaks from '../Breaks/Breaks';
import Cases from '../Cases/Cases';
import Consultancies from '../Consultancies/Consultancies';

import bg from '../Login/bg.png'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import {Typography, TextField, Button, Alert, Box, AppBar, Toolbar,Snackbar, IconButton, createTheme, Icon, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Menu, MenuItem, Stack} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import { Avatar } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EventIcon from '@mui/icons-material/Event';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import GavelIcon from '@mui/icons-material/Gavel';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useState } from 'react';
import { styled, typography } from '@mui/system';
import moment from "moment";

import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../features/userData';


const PAGES_TITLES = {
  '/': 'Homepage',
  '/breaks': 'Breaks',
  '/cases': 'Cases',
  '/consultancies': 'Consultancies',
}

const NotifcationsButton = (props) => {
  return (
    <IconButton onClick={props.handleNotifClick}>
      <NotificationsIcon/>
    </IconButton>
  )
}
const NotificationsMenu = (props) => {
  return (
    <Menu anchorEl={props.notifAnchorEl} open={props.notifOpen} onClose={props.handleNotifClose}>
      <MenuItem key={'1'}>Notification 1</MenuItem>
      <MenuItem key={'2'}>Notification 2</MenuItem>
      <MenuItem key={'3'}>Notification 3</MenuItem>
      <MenuItem key={'4'}>Notification 4</MenuItem>
    </Menu>
  )
}
const Dashboard = (props) => {
    const theme = props.theme
    const showToast = props.showToast
    const dispatch = useDispatch()

    const currPage = useLocation()
    const [expiresIn, setExpiresIn] = useState(60 * 60)

    const userData = useSelector((state) => state.userData.value)

    useEffect(() => {
      if (userData.loggedIn)
      {
        setExpiresIn(Math.floor(userData.expiresIn / 60))
        const timerId = setInterval(() => {
          setExpiresIn((prevValue) => prevValue - 1)
        }, 1000 * 60)
      }
    }, [])
    

    const functionalities = [
      ['Calendar', <EventIcon/>, "View your timeline and urgent task for the upcoming period.", "calendar"],
      ['Breaks', <LinkOffIcon/>, "Add new breaks, track and filter existing ones all in one place.", "breaks"],
      ['Cases', <GavelIcon/>, "Add new cases, track and filter existing ones all in one place.", "cases"],
      ['Consultancy', <ContactSupportIcon/>, "Get help regarding ECB stuff.", "consultancy"],
      ['Extensions', <MoreTimeIcon/>, "??????", "extensions"],
      ['Early Finishes', <AssignmentTurnedInIcon/>, "??????", "finishes"]
    ];
    const [open, setOpen] = useState(false);

    const drawerWidth = 240;
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const drawer = (
    <>
      <DrawerHeader  sx={{backgroundColor: theme.palette.background.default}} >
        <Avatar sx={{m: '12px'}}/>
        <List>
          <Typography fontWeight='bold' sx={{color: theme.palette.primary.main}}>ECB Plaza</Typography>
          <Typography sx={{color: theme.palette.secondary.main}}>Hi {userData.fullName}!</Typography>
          <Typography sx={{color: theme.palette.background.paper}}>{(expiresIn > 0)? `Your session expires in ${expiresIn} minutes`: 'Your session has expired. Please refresh the page.'}</Typography>
        </List>
      </DrawerHeader>
      <Divider />
      <List sx={{color:theme.palette.secondary.main}}>
        {functionalities.map((button, index) => (
          <ListItem button key={button[0]} onClick={() => setOpen(false)} component={Link} to={`/${button[3]}`}>
            <ListItemIcon>
              {button[1]}
            </ListItemIcon>
            <ListItemText primary={button[0]} />
          </ListItem>
        ))}
      </List>
      </>
  )
  const Homepage = () => {
    return (
      <>
              <DetailsDialog/>
              <Typography variant='h3' align='center' paragraph sx={{fontSize: '5vw', fontFamily: 'Corben', color: theme.palette.primary.main, m:'32px'}}>
                Welcome to ECB Greece Plaza
              </Typography>
              <Typography paragraph align='center' fontWeight='bold' sx={{color: theme.palette.secondary.main}} >
                This website is go-to destination for all things related to ECB.<br/>Here you can check:
              </Typography>
              <Grid align='center' sx={{width: '100%'}} container>
                {
                  functionalities.map((functionality) => (
                    <Grid item key={functionality[0]} xs={12} sm={4}  sx={{padding: '8px'}}>
                      <Paper elevation={10} sx={{backgroundColor: theme.palette.secondary.main}} >
                          <Icon sx={{m:'4px', color:'#243037'}}>
                            {functionality[1]}
                          </Icon>
                        <Typography variant='h5' sx={{color:'#243037'}}>{functionality[0]}</Typography>
                        <Typography sx={{m:'8px', paddingBottom:'8px', color:'#243037'}}>{functionality[2]}</Typography>
                        <Button variant="contained" fullWidth onClick={() => {
                          setDialogData({title: functionality[0], description: functionality[2]})
                          setDialogOpen(true)
                          }}>Learn More</Button>
                      </Paper>
                    </Grid>
                  ))
                }
                
              </Grid>
    </>
    )
  }
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const DetailsDialog = (props) => {

    return (
      <Dialog height="50vh"
      open={dialogOpen}
    >
      <DialogTitle sx={{color: theme.palette.primary.main}}>
        {dialogData.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color: theme.palette.secondary.main}}>
          {dialogData.description} {dialogData.description} {dialogData.description} {dialogData.description} {dialogData.description} {dialogData.description} {dialogData.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {setDialogOpen(false)}}>Close</Button>
      </DialogActions>
    </Dialog>
    )
  }

  const [notifAnchorEl, setNotifAnchorEl] = useState(null);
  const notifOpen = Boolean(notifAnchorEl);
  const handleNotifClick = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };
  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const AccountDialog = (props) => {
    return (
      <Dialog open={props.accountDialogOpen} onClose={() => props.setAccountDialogOpen(false)}>
        <DialogTitle>{userData.fullName}</DialogTitle>
        <DialogContent>
        <Stack
          spacing={1}
          display="flex"
          justifyContent="center"
          alignItems="center">
            <Avatar>
              <img src="https://source.unsplash.com/random/120x120"></img>
            </Avatar>
            <Typography>{userData.email}</Typography>
            <Typography>{userData.lc}</Typography>
            <Typography>{userData.department}</Typography>
            <Typography>{userData.position}</Typography>

            <Button variant='contained' onClick={() => dispatch(logout())}>
              Log Out
            </Button>


          </Stack>
        </DialogContent>
      </Dialog>
    )
  }
return (
    <Grid container sx={{height: "100vh"}}>
        <Box  sx={{ display: 'flex', width: '100%'}}>
            <CssBaseline/>
            <AppBar
              position="fixed"
              sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
              }}
            >
              <Toolbar sx={{justifyContent: 'space-between'}}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={()=> setOpen(!open)}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" fontWeight='bold' noWrap component="div">
                {PAGES_TITLES[currPage.pathname]}
                </Typography>
              <Box>
                {/* <NotifcationsButton handleNotifClick={handleNotifClick}/> */}
                <NotifcationsButton/>
                <IconButton onClick={() => setAccountDialogOpen(true)}>
                  <AccountCircleIcon/>
                </IconButton>
                
              </Box>
                <NotificationsMenu notifAnchorEl={notifAnchorEl} notifOpen={notifOpen} handleNotifClose={handleNotifClose}/>
              </Toolbar>
            </AppBar>
            
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
              variant="temporary"
              open={open}
              onClose={() => setOpen(!open)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
          </Drawer>
          <Drawer
            PaperProps={{ elevation: 20 }}
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
            </Box>
            <Box component="main" sx={{backgroundImage : `url(${bg})`, backgroundColor: theme.palette.secondary.default, flexGrow: 1, p: 3 }} >
              <DrawerHeader/>
              <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="breaks" element={<Breaks showToast={showToast} theme={theme}/>} />
                <Route path="cases" element={<Cases showToast={showToast} theme={theme}/>} />
                <Route path="consultancy" element={<Consultancies showToast={showToast} userData={userData} theme={theme}/>} />

              </Routes>
            </Box>
          </Box>
          <AccountDialog accountDialogOpen={accountDialogOpen} setAccountDialogOpen={setAccountDialogOpen}/>
    </Grid>

)
}
export default Dashboard