import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { configureStore } from '@reduxjs/toolkit';
import {Provider} from 'react-redux';

import userDataReducer from './features/userData';
import breaksDataReducer from './features/breaksData';
import casesDataReducer from './features/casesData';
import consultanciesDataReducer from './features/consultanciesData';

const store = configureStore({
  reducer: {
    userData: userDataReducer,
    breaksData: breaksDataReducer,
    casesData: casesDataReducer,
    consultanciesData: consultanciesDataReducer,

  }
})
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
