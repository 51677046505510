import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { 
    currCase: {},
    cases: []
}

export const casesDataSlice = createSlice({
    name: 'casesData',
    initialState: {
        value: initialStateValue
    },
    reducers: {
        setCurrCase: (state, action) =>  {
            state.value.currCase = action.payload
        },
        updateCurrCase: (state, action) => {
            const { key, value } = action.payload
            state.value.currCase[key] = value
        },
        setCases: (state, action) => {
            state.value.cases = action.payload
        },
        updateCase: (state, action) => {
            const caseIndex = state.value.cases.findIndex((oldCase) => oldCase._id === action.payload._id)
            if (caseIndex !== -1) {
                state.value.cases[caseIndex] = action.payload
            }
            else {
                state.value.cases.push(action.payload)
            }
        },
    }
})

export const { setCurrCase, updateCurrCase, setCases, updateCase } = casesDataSlice.actions

export default casesDataSlice.reducer