import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {id: 0, token: '', email: '', fullName: '', expiresIn: 0, token: '', loggedIn: false}

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        value: initialStateValue
    },
    reducers: {
        login: (state, action) =>  {
            window.localStorage.setItem('token', action.payload.token)
            window.localStorage.setItem('userData', JSON.stringify(action.payload))
            state.value = action.payload
        },
        logout: (state) => {
            window.localStorage.setItem('token', '')
            state.value = initialStateValue
        },
    }
})

export const { login, logout } = userDataSlice.actions

export default userDataSlice.reducer