import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { 
    currBreak: {},
    breaks: []
}

export const breaksDataSlice = createSlice({
    name: 'breaksData',
    initialState: {
        value: initialStateValue
    },
    reducers: {
        setCurrBreak: (state, action) =>  {
            state.value.currBreak = action.payload
        },
        updateCurrBreak: (state, action) => {
            const { key, value } = action.payload
            state.value.currBreak[key] = value
        },
        setBreaks: (state, action) => {
            state.value.breaks = action.payload
        },
        updateBreak: (state, action) => {
            const breakIndex = state.value.breaks.findIndex((oldBreak) => oldBreak._id === action.payload._id)
            if (breakIndex !== -1) {
                state.value.breaks[breakIndex] = action.payload
            }
            else {
                state.value.breaks.push(action.payload)
            }
        },
    }
})

export const { setCurrBreak, updateCurrBreak, setBreaks, updateBreak } = breaksDataSlice.actions

export default breaksDataSlice.reducer