import { GREEK_ENTITIES, COUNTRIES } from '../constants';
import {Typography, Paper, ToolTip, Stack, ListItem, Autocomplete, Fab, Slide, Divider, ListItemText, List, TextField, Button, Box, AppBar, Toolbar, IconButton, createTheme, Icon, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Menu, MenuItem, Tooltip, Select, InputLabel, FormControl, Grid} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import HistoryIcon from '@mui/icons-material/History';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrConsultancy, updateCurrConsultancy, setConsultancies, updateConsultancy } from '../features/consultanciesData';

const CONSULTANCIES_FIELDS = {
  'Name': 'name',
  'Email': 'email',
  'Position': 'position',
  'LC': 'lc',
  'Reason of Consultancy': 'consultReason',
  'Status': 'status',
}
const CONSULTANCIES_IDS = Object.fromEntries(Object.entries(CONSULTANCIES_FIELDS).map(a => a.reverse()))

const DROPDOWN_CHOICES = {
  'position': ['', 'Member', 'TL', 'Manager', 'LCVP', 'LCP'],
  'lc': GREEK_ENTITIES,
  'status': ['Open', 'Closed'], 
}

const AddConsultancyFAB = (props) => {
  const addConsultancy = () => {
    const dispatch = props.dispatch
    const userData = props.userData
    props.setEditMode(false)
    var newConsultancy = Object.fromEntries(Object.entries(CONSULTANCIES_IDS).map(a => [a[0], '']))
    newConsultancy = {...newConsultancy, name: userData.fullName, email: userData.email, lc: userData.lc, position: userData.position, status: 'Open'}
    dispatch(setCurrConsultancy(newConsultancy))
    props.setEditDialogOpen(true)
  }
  const theme = props.theme
  return(
    <Fab onClick={addConsultancy} color="primary" sx={{position: 'fixed', bottom: theme.spacing(4), right: theme.spacing(4)}}>
      <AddIcon/>
    </Fab>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const onInputChange = (value, fieldId, dispatch) => {
  dispatch(updateCurrConsultancy({key: fieldId, value: value}))
}

const DropdownInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
        <TextField select fullWidth label={CONSULTANCIES_IDS[fieldId]} value={value}  onChange={(e) => onInputChange(e.target.value, fieldId, dispatch)} >
          {DROPDOWN_CHOICES[fieldId].map((choice) => {
          return <MenuItem key={choice} value={choice}>{choice}</MenuItem>
          })}
          
        </TextField>
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const AutocompleteInput = React.memo((props) => {
  const {fieldId, strValue, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
        <Autocomplete fullWidth
          disablePortal
          value={{name: strValue}}
          options={DROPDOWN_CHOICES[fieldId]}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => onInputChange(value.name, fieldId, dispatch)}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name}
              
            </Box>
          )
        }
          renderInput={(params) => <TextField {...params} fullWidth label={CONSULTANCIES_IDS[fieldId]}/>}
        />
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const DateInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props
  return (
    <>
      <ListItem key={fieldId}>
          <DatePicker
            label={CONSULTANCIES_IDS[fieldId]}
            value={value? moment(value, 'DD-MM-YYYY'): null}
            inputFormat='DD/MM/YYYY'
            onChange={(e) => onInputChange(e? e.format('DD/MM/YYYY'): '', fieldId, dispatch)}   
            renderInput={(params) => <TextField {...params} fullWidth/>}
        />
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const DateRangeInput = React.memo((props) => {
  const {fieldId, strValue, dispatch} = props
  
  const onRangeChanged = (value, fieldId ,index) => {
  const currentRange = strValue? strValue.split('-'): ['', '']
  currentRange[index] = value
  onInputChange(currentRange.join('-'), fieldId, dispatch)
  }
  return(
    <>
      <ListItem key={fieldId}>
        <Stack direction='row' sx={{width:'100%'}}>
          <DatePicker
              label={CONSULTANCIES_IDS[fieldId] + ' (FIRST DAY)'}
              value={strValue? moment(strValue.split('-')[0], 'DD-MM-YYYY'): null}
              inputFormat='DD/MM/YYYY'
              onChange={(e) => onRangeChanged(e? e.format('DD/MM/YYYY'): '', fieldId, 0)}   
              renderInput={(params) => <TextField {...params} fullWidth/>}
          />
          <DatePicker
              label={CONSULTANCIES_IDS[fieldId] + ' (LAST DAY)'}
              value={strValue? moment(strValue.split('-')[1], 'DD-MM-YYYY'): null}
              inputFormat='DD/MM/YYYY'
              onChange={(e) => onRangeChanged(e? e.format('DD/MM/YYYY'): '', fieldId, 1)}   
              renderInput={(params) => <TextField {...params} fullWidth/>}
          />
        </Stack>
         
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const TextInput = React.memo((props) => {
  const {fieldId, value, dispatch} = props

  return (
    <>
      <ListItem key={fieldId}>
        <TextField fullWidth multiline={fieldId === 'consultReason'} type={fieldId.includes('Id')? 'number': 'text'} label={CONSULTANCIES_IDS[fieldId]} variant="outlined" value={value} onChange={(e) => onInputChange(e.target.value, fieldId, dispatch)}/>
      </ListItem>
      <Divider key={fieldId + ' div'}/>
    </>
  )
})

const EditDialog = (props) => {
  const dispatch = props.dispatch
  
  const currConsultancy = useSelector(((state) => state.consultanciesData.value.currConsultancy))

  const showToast = props.showToast

  const updateLocalConsultanciesData = props.updateLocalConsultanciesData
  const editDialogOpen = props.editDialogOpen
  const handleEditDialogClose = props.handleEditDialogClose
  const oldDialogData = props.oldDialogData

  const saveConsultancyChanges = () => {
    const consultancyId = currConsultancy._id // for put request only (for post receive the _id from server)
    const {_id, ...consultancyWithoutId} = currConsultancy;

    if (props.editMode) {
      var editedFields = []
      var newValues = []
      var oldValues = []
      Object.entries(currConsultancy).map(([key, value]) => {
        if (key!== '_id' && key!== 'editHistory' && value !== oldDialogData[key]) {
          editedFields.push(key)
          newValues.push(value)
          oldValues.push(oldDialogData[key])
        }
      })
      const editHistory = {
        fullName: JSON.parse(window.localStorage.getItem('userData')).fullName,
        editorId: JSON.parse(window.localStorage.getItem('userData'))._id,
        action: 'edit',
        editDate : moment().utc().toISOString(),
        editedFields: editedFields,
        newValues: newValues,
        oldValues: oldValues
      }
      const updatedConsultancy = {data: currConsultancy, editHistory: editHistory}
      const promise = axios.put(process.env.REACT_APP_HEROKU_URL + 'consultancies/' + consultancyId, updatedConsultancy, { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')}})
      promise.then(((response) => {
        if (response.status === 201) {
          showToast('success', response.data.data)
          updateLocalConsultanciesData({_id: consultancyId, name: currConsultancy.name, position: currConsultancy.position, lc: currConsultancy.lc, status: currConsultancy.status})
          handleEditDialogClose()
        }
        else {
          showToast('error', response.data.error)
        }
      }))
    }
    else {
      const editHistory = {fullName: JSON.parse(window.localStorage.getItem('userData')).fullName,
      editorId: JSON.parse(window.localStorage.getItem('userData'))._id,
      action: 'create',
      editDate : moment().utc().toISOString()
    }
      const createdConsultancy = {data: consultancyWithoutId, editHistory: editHistory}
      const promise = axios.post(process.env.REACT_APP_HEROKU_URL + 'consultancies/', createdConsultancy, { headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')}})
      promise.then(((response) => {
        if (response.status == 201) {
          showToast('success', response.data.data)
          updateLocalConsultanciesData({_id: response.data._id, name: consultancyWithoutId.name, position: consultancyWithoutId.position, lc: consultancyWithoutId.lc, status: consultancyWithoutId.status})
          handleEditDialogClose()
        }
        else {
          showToast('error', response.data.error)
        }
      }))
    }
    
  }
  return (
    <Dialog
      fullScreen
      open={editDialogOpen}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'fixed' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleEditDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {(props.editMode)? 'Edit Consultancy for ' + currConsultancy.name: 'Add New Consultancy'}
          </Typography>
          <Button color="inherit" onClick={saveConsultancyChanges}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <List sx={{mt: '80px'}}>
      {Object.entries(CONSULTANCIES_IDS).map(([fieldId, value]) => {
        if (!['_id'].includes(fieldId)){
          if (['lc', 'status'].includes(fieldId))
          {
            return(
              <DropdownInput fieldId={fieldId} value={currConsultancy[fieldId]} dispatch={dispatch}/>
            )
          }
          else {
            return(
              <TextInput fieldId={fieldId} value={currConsultancy[fieldId]} dispatch={dispatch}/>
            )
          }
          
        }
        }
      )}
      </List>
    </Dialog>
  )
}
const HistoryDialog = (props) => {
  const theme = props.theme
  const showToast = props.showToast

  const historyDialogOpen = props.historyDialogOpen
  const setHistoryDialogOpen = props.setHistoryDialogOpen

  const historyData = props.historyData

  const handleProfileClick = (profileId) => {
    const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'users/' + profileId ,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} })
        promise.then((response) => {
          console.log(response)
          if (response.status == 200) {
            const userInfo = response.data
            showToast('info', `${userInfo.fullName} (${userInfo.email}) | ${userInfo.lc}${['LCP', 'MCP'].includes(userInfo.position)? '': ' | ' + userInfo.email} - ${userInfo.position}`)
          }
          else {
            showToast('error', response.data.error)
          }
          })

  }
  return (
    <Dialog fullWidth height="50vh"
    
    open={historyDialogOpen}
  >
    <DialogTitle sx={{color: theme.palette.primary.main}}>
      Edits History
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{color: theme.palette.secondary.main}}>
        {historyData.map((change) => {
          if (change.action == 'edit')
          {
            return(
              <Paper key={change._id} elevation={3} sx={{mb: '40px', padding: '20px'}}>
              <Stack>
                <Typography fontWeight='bold'>{change.fullName}</Typography>
                <Button onClick={() => handleProfileClick(change.editorId)}>Visit Profile</Button>
                <Typography sx={{mb: '20px'}}>{moment.utc(change.editDate).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</Typography>
              </Stack>
              <Divider/>
              <Stack direction="column" spacing={1}>
                {change.editedFields.map((field, i) =>(
                  <Paper key={field}>
                    {`${CONSULTANCIES_IDS[field]}: ${change.oldValues[i]? `"${change.oldValues[i]}"`: 'EMPTY' }  =>  "${change.newValues[i]}"`}
                  </Paper>
                ))}
                
              </Stack>
              <Divider/>
              </Paper>
            )
            }
            else if (change.action == 'create') {
              return (
                <Paper elevation={3} sx={{mb: '40px', padding: '20px'}}>
              <Stack>
                <Typography fontWeight='bold'>{change.fullName}</Typography>
                <Button onClick={() => handleProfileClick(change.editorId)}>Visit Profile</Button>
                <Typography sx={{mb: '20px'}}>This consultancy was created on {moment.utc(change.editDate).local().format("dddd, MMMM Do YYYY, h:mm:ss a")}</Typography>
              </Stack>
              <Divider/>
              </Paper>
              )
            }
          
          
        })}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {setHistoryDialogOpen(false)}}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}
const DetailsDialog = (props) => {
  const currConsultancy = useSelector(((state) => state.consultanciesData.value.currConsultancy))

  const {theme, dialogOpen, setDialogOpen} = props
  var data = {
    'Consultancy ID': currConsultancy._id,
    'Name': currConsultancy.name,
    'Email': currConsultancy.email,
    'LC': currConsultancy.lc,
    'Reason of Consultancy': currConsultancy.consultReason,
    'Status': currConsultancy.status,
  }
  return (
    <Dialog fullWidth height="50vh"
    
    open={dialogOpen}
  >
    <DialogTitle sx={{color: theme.palette.primary.main}}>
      {currConsultancy.email}
    </DialogTitle>
    <DialogContent>
      <DialogContentText sx={{color: theme.palette.secondary.main}}>
        {Object.entries(data).map(([key, value]) => {
          if (value){
            return(
              <>
              <Typography textAlign='center' fontWeight='bold'>{key}:</Typography>
              <Typography textAlign='center'>{value.split('\n').map(line => <>{line}<br/></>)}</Typography>
              <br/>
            <Divider/>
            <br/>
            </>
            )
          }
          
        }
      )}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {setDialogOpen(false)}}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}
const Consultancies = (props) => {
  const dispatch = useDispatch()
  const consultanciesData = useSelector((state) => state.consultanciesData.value.consultancies)
  const userData = props.userData
  const showToast = props.showToast

  const [selectedRows, setSelectedRows] = useState([])
  const [editMode, setEditMode] = useState(true)
  const updateLocalConsultanciesData = (newEntry) => {
    dispatch(updateConsultancy(newEntry))
  }
  useEffect(() => {
    const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'consultancies/all',{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setConsultancies(response.data))
      }
      else {
        showToast('error', 'An error has occured.')
      }
    })
  }, [])
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const handleEditDialogClose = () => {
    setEditDialogOpen(false)
  }

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false)
  const [historyData, setHistoryData] = useState([])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [oldDialogData, setOldDialogData] = useState({})

 
  const columns = [
    {field: "name", headerName: "Name", width: 150},
    {field: "position", headerName: "Position", width: 150},
    {field: "lc", headerName: "LC", width: 150},
    {field: "status", headerName: "Status", width: 150},
    {field: "actions", headerName: "Actions", width: 150, renderCell: (values) => {
      const onViewClick = (e) =>{
        e.stopPropagation()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'consultancies/' + values.row._id,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setCurrConsultancy(response.data))
        setDialogOpen(true)
      }
      else {
        showToast('error', response.data.error)
      }
      })
      .catch((error) => {
        showToast('error', "Couldn\'t get this case details.")
      })
    }
      const onEditClick = (e) =>{
        e.stopPropagation()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'consultancies/' + values.row._id,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} } )
    promise.then((response) => {
      if (response.status == 200) {
        dispatch(setCurrConsultancy(response.data))
        setOldDialogData(response.data)
        setEditMode(true)
        setEditDialogOpen(true)
      }
      else {
        showToast('error', response.data.error)
      }
      })
      }
      const onHistoryClick = (e) => {
        e.stopPropagation()
        axios.interceptors.response.use()
        const promise = axios.get(process.env.REACT_APP_HEROKU_URL + 'consultancies/' + values.row._id + '/history' ,{ headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token')} })
        promise.then((response) => {
          if (response.status == 200) {
            setHistoryData(response.data.editHistory)
            setHistoryDialogOpen(true)
          }
          else {
            showToast('error', response.data.error)
          }
          })
      }
      return (
        <Box>
          <Tooltip title="View Consultancy">
          <IconButton onClick={onViewClick} color="primary" component="span">
            <VisibilityIcon />
          </IconButton>
          </Tooltip>

          <Tooltip title="Edit Consultancy">
          <IconButton onClick={onEditClick} color="primary" component="span">
            <EditIcon />
          </IconButton>
          </Tooltip>

          <Tooltip title="View Edits History">
            <IconButton onClick={onHistoryClick} color="primary" component="span">
              <HistoryIcon/>
            </IconButton>
          </Tooltip>
    
    </Box>
    )
    }
  }
  ]
  const theme = props.theme;
  return (
      <Box component="main" sx={{ flexGrow: 1, p: 3}} >
           <HistoryDialog 
           showToast={showToast}
           dispatch={dispatch}
           historyData={historyData}
           historyDialogOpen={historyDialogOpen}
           setHistoryDialogOpen={setHistoryDialogOpen}
           theme={theme}/>
           <DetailsDialog theme={theme} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen}/>
           <EditDialog 
              showToast={showToast}
              dispatch={dispatch}
              editDialogOpen={editDialogOpen}
              oldDialogData={oldDialogData}
              handleEditDialogClose={handleEditDialogClose}
              updateLocalConsultanciesData={updateLocalConsultanciesData}
              editMode={editMode}
           />
            <Typography variant='h3' align='center' paragraph sx={{fontSize: '5vw', fontFamily: 'Corben', color: theme.palette.primary.main}}>
              Consultancy
            </Typography>
            <Typography paragraph align='center' fontWeight='bold' sx={{color: theme.palette.secondary.main}} >
              You can view and edit {consultanciesData.length} consultancies.
            </Typography>
            <Button startIcon={<ContentCopyIcon/>} variant='contained' sx={{m:'20px'}} onClick={() => {
              if (selectedRows.length > 0) {
                var clipboardData = [['Consultancy ID', 'EP Name', 'EP ID', 'Opportunity ID', 'Country', 'Category'].join('\t')]
                clipboardData = clipboardData.concat(consultanciesData.filter((row)=> (selectedRows.includes(row._id))).map((row) => (Object.values(row).join('\t'))))
                navigator.clipboard.writeText(clipboardData.join('\n'))
                showToast('success', 'Selected consultancies were copied to Clipboard. You can now paste them in a Google Sheet.')
              }
              else {
                showToast('error', 'Selection is empty. Use the checkbox to select some consultancies first.')
              }
              
            }
            }>Copy Selected Consultancies</Button>
            <div style={{ height: 400, width: '100%' }}>
            <DataGrid 
            getRowId={(row) => row._id}
            rows={consultanciesData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
            onSelectionModelChange={(selectedRows) => {
              setSelectedRows(selectedRows)
            }}
            stickyHeader
          />
            </div>
            {/* <Typography variant='h5' fontWeight='bold' sx={{mt: '40px', mb: '20px'}}color={theme.palette.primary.main}>Stats:</Typography>
            <Paper elevation={4} sx={{width: '100%', height: '200px'}}>

            </Paper> */}
            <AddConsultancyFAB theme={theme} userData={userData} dispatch={dispatch} setEditMode={setEditMode} setEditDialogOpen={setEditDialogOpen}/>
          </Box>
  )
}
export default Consultancies